<template>
  <div>
    <slider ref="slider" :options="options" @slide="slideIndex">
      <slideritem
        v-for="template in templates"
        :key="template.id"
        :style="{ width: '31.4%', 'margin-right': '20px' }"
        ><input
          type="radio"
          @change="check"
          :checked="template.id == selected"
          :value="template.id"
          class="check-img"
        />
        <div class="d-flex flex-column align-items-end">
          <Card :class="[{ isActive: value == template.id }]">
            <div class="thumb-container py-3" slot="card-body">
              <iframe class="thumbnail" :srcdoc="template.html" />
            </div>
          </Card>
        </div>
      </slideritem>
    </slider>
    <div class="d-flex justify-content-center mt-2" style="width: 78vw">
      <b-button
        @click="$refs.slider.$emit('slidePre')"
        pill
        class="mr-2"
        variant="outline-info"
        ><fa-icon icon="arrow-left"
      /></b-button>
      <b-button
        @click="$refs.slider.$emit('slideNext')"
        pill
        class="mr-2"
        variant="outline-info"
      >
        <fa-icon icon="arrow-right"
      /></b-button>
    </div>
  </div>
</template>

<script>
import { slider, slideritem } from "vue-concise-slider";
export default {
  props: {
    selected: {
      type: String,
    },
    title: {
      type: String,
    },
    templates: {
      type: Array,
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      slideIndex: 0,
      html: "",
      creating: false,
      invalidTags: [],
      requiredTags: [],
      options: {
        itemAnimation: true,
        loopedSlides: 1,
        slidesToScroll: 1,
        thresholdDistance: 200,
        pagination: false,
        currentPage: 0,
      },
    };
  },
  components: {
    slider,
    slideritem,
    MailEditor: () => import("@/components/EmailEditor"),
  },
};
</script>

<style lang="scss">
.icon-style {
  font-size: 3rem;
  margin-bottom: 1rem;
}
.thumbnail {
  position: relative;
  -ms-zoom: 0.35;
  -moz-transform: scale(0.35);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.35);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.35);
  -webkit-transform-origin: 0 0;
  width: 285%;
  border: none;
  height: 65vh;
}
.thumb-container {
  height: 30vh;
}
.thumbnail-bg {
  position: relative;
  -ms-zoom: 0.35;
  -moz-transform: scale(0.35);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.35);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.35);
  -webkit-transform-origin: 0 0;
  width: 285%;
  border: none;
  height: 65vh;
}
.thumb-container-bg {
  align-self: center;
  width: 16vw;
  height: 30vh;
}
.isActive {
  border: 1px solid #007bff;
}
.check-img {
  width: 25px;
  height: 25px;
  margin-right: 10px !important;
}
</style>